import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const defaults = {
        loop: false,
        margin: 0,
        nav: true,
        dots: false,
        touchDrag: true,
        mouseDrag: false,
        responsive: {
            0: { autoHeight: true, autoplay: false, items: 1 },
            580: {
                autoHeight: false,
                autoplay: false,
                autoplayHoverPause: true,
                autoplayTimeout: 6000, //  In milliseconds
                items: 2,
            },
            970: { items: 3 },
        },
    };
    const imageCarouselSettings = {
        loop: false,
        margin: 0,
        nav: true,
        dots: false,
        touchDrag: true,
        mouseDrag: false,
        responsive: {
            0: { autoHeight: true, autoplay: false, items: 1 },
            768: {
                autoHeight: false,
                autoplay: false,
                autoplayHoverPause: true,
                autoplayTimeout: 6000, //  In milliseconds
                items: 2,
            },
        },
    };
    $('.content-carousel').owlCarousel(defaults);
    $('.carousel').owlCarousel(imageCarouselSettings);
});
