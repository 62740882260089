import {
    $, addAction, INIT, _,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $els = $(
        'main h1, main h2, .featured-content p, .content-row--featured-content p, .leader-card, .post-action-card, .capability',
    );
    let count = 0;
    const resetCount = _.debounce(
        () => {
            count = 0;
        },
        500,
        { leading: false },
    );
    SetDesign.scrollWatch($els, ($el) => {
        setTimeout(() => {
            count += 1;

            if (count > 10) {
                count = 10;
            }

            $el.addClass(`animate animate--delay-${count}`);
            resetCount();
        }, 100);
    });
});
