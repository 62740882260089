import { $, addAction, INIT } from '@situation/setdesign.util';

const activeDuration = 3000;
let enterDuration;
let leaveDuration;

function delay(time, callback) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(callback());
        }, time);
    });
}

function duration(el) {
    return parseFloat($(el).css('transition-duration')) * 1000;
}

function rotateWord(element) {
    const $el = $(element);
    $el.addClass('active-enter');
    return delay(enterDuration, () => $el.removeClass('active-enter').addClass('active'))
        .then(() => delay(activeDuration, () => $el.removeClass('active').addClass('active-leave')))
        .then(() => delay(leaveDuration, () => $el.removeClass('active-leave')));
}

function rotateWords(elements, index) {
    rotateWord(elements[index]).then(() => {
        const nextIndex = (index + 1) % elements.length;
        rotateWords(elements, nextIndex);
    });
}

addAction(INIT, () => {
    $('.word-rotator').each(function cb() {
        const $words = $(this).find('.word');
        // calculate max word width
        const widths = $words.map(function cb1() {
            const $word = $(this);
            const width = $word.show().width();
            $word.removeAttr('style');
            return width;
        });
        $(this).css('width', Math.max(...widths));
        // read transition durations
        const $first = $words.first().hide();
        enterDuration = duration($first.addClass('active-enter'));
        leaveDuration = duration($first.removeClass('active-enter').addClass('active-leave'));
        $first.removeClass('active-leave').removeAttr('style');
        rotateWords($(this).find('.word'), 0);
    });
});
