import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $section = $('.match-bg-aspect');
    if (!$section.length) {
        return;
    }

    const dim = {};

    function setHeight() {
        $section.each((i, el) => {
            const $this = $(el);
            $this.css('min-height', '');
            const $el = $this
                .find('.content-background-image__img--desktop')
                .filter((j, e) => $(e).is(':visible'));
            if (!$el.length) {
                return true;
            }

            const sectionWidth = $this.outerWidth();
            const imgSrc = $el[0].style.backgroundImage
                .replace(/url\((['"])?(.*?)\1\)/gi, '$2')
                .split(',')[0];
            if (!dim.hasOwnProperty(imgSrc)) {
                const image = new Image();

                image.onload = function imageOnload() {
                    dim[imgSrc] = { width: this.width, height: this.height };
                    $this.css(
                        'min-height',
                        `${(sectionWidth * dim[imgSrc].height) / dim[imgSrc].width}px`,
                    );
                };

                image.src = imgSrc;
            } else {
                $this.css(
                    'min-height',
                    `${(sectionWidth * dim[imgSrc].height) / dim[imgSrc].width}px`,
                );
            }
            return true;
        });
    }

    addAction(LAYOUT, setHeight);
});
